import React, { useRef, useState } from 'react';
import Chart from "react-apexcharts";
import checkImg from '../Icons/check.png';

const selecttheme = localStorage.getItem('theme');

const ApexChart = ({ categories, data, data2, color }) => {
  const chartRef = useRef(null);
  const [activeSeries, setActiveSeries] = useState({
    "Relatif Getiri": true,
    "Kümülatif Getiri": true
  });
  const options = {
    // chart: {
    //   type: "area",
    //   background: "#00223C",
    //   height: 650,
    //   animations: {
    //     enabled: true, //false
    //     easing: "easeinout", //linear easein easeout easeinout
    //     speed: 900,
    //     animateGradually: {
    //       enabled: false,
    //     },
    //     dynamicAnimation: {
    //       enabled: true,
    //       speed: 350,
    //     },
    //   },
    //   toolbar: {
    //     show: false,
    //     offsetX: 0,
    //     offsetY: 0,
    //     tools: {
    //       download: false,
    //       selection: true,
    //       zoom: true,
    //       zoomin: false,
    //       zoomout: false,
    //       pan: false,
    //       reset: false,
    //       customIcons: [],
    //     },
    //     export: {
    //       csv: {
    //         filename: undefined,
    //         columnDelimiter: ",",
    //         headerCategory: "category",
    //         headerValue: "value",
    //       },
    //       svg: {
    //         filename: undefined,
    //       },
    //       png: {
    //         filename: undefined,
    //       },
    //     },
    //     autoSelected: "pan",
    //   },

    //   dropShadow: {
    //     enabled: true,
    //     blur: 9,
    //     color: "#000",
    //     opacity: 0.35,
    //   },
    // },
    chart: {
      type: 'line',
      zoom: {
        type: 'x',
        enabled: false,
        autoScaleYaxis: false
      },
      toolbar: {
        show: false
      }
    },
    zoom: {
      autoScaleYaxis: true,
      autoScaleXaxis: true,
    },
    colors: ["#2ECE5B"],
    legend: {
      show: false,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: "bottom",
      horizontalAlign: "center",
      floating: false,
      fontSize: "11px",
      fontFamily: "Helvetica, Arial",
      fontWeight: 400,
      formatter: undefined,
      inverseOrder: false,
      width: undefined,
      height: undefined,
      tooltipHoverFormatter: undefined,
      customLegendItems: [],
      offsetX: 0,
      offsetY: 0,
      labels: {
        colors: undefined,
        useSeriesColors: false,
      },
      markers: {
        width: 11,
        height: 11,
        strokeWidth: 0,
        strokeColor: "#fff",
        fillColors: undefined,
        radius: 11,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
    noData: {
      text: "Data Bulunamadı.",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "14px",
      },
    },
    markers: {
      size: 4,
      strokeColors: "#2ECE5B",
      strokeWidth: 2,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 1,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 3,
      },
    },
    xaxis: {
      type: "datetime",
      categories: categories,
      tickPlacement: "on",
      axisBorder: {
        show: false,
        color: '#FFFFFF'
      },
      axisTicks: {
        show: false,
      },
      floating: false,
      labels: {
        show: true,
        rotate: -90,
        rotateAlways: false,
        hideOverlappingLabels: true,
        showDuplicates: false,
        trim: true,
        minHeight: undefined,
        maxHeight: 120,
        style: {
          colors: '#AAAAAA',
          fontSize: "8px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 400,
          cssClass: "apexcharts-xaxis-label",
        },
        offsetX: 0,
        offsetY: 0,
        format: "dd.MM.yy",
        formatter: undefined,
        datetimeUTC: true,
        datetimeFormatter: {
          year: "yyyy",
          month: "MMM 'yy",
          day: "dd MMM",
          hour: "HH:mm",
        },
      },
    },
    yaxis: {
      forceNiceScale: true,
      labels: {
        align: "center",
        style: {
          fontSize: "8px",
          colors: '#AAAAAA',
        },
      },
      markers: {
        colors: [color],
      },
      tooltip: {
        enabled: true,
        offsetX: 0,
      },
      axisBorder: {
        show: true,
        color: '#FFFFFF',
        offsetX: 0,
        offsetY: 0,
      },
    },
    stroke: {
      curve: "smooth", //stepline straight
      show: true,
      lineCap: "butt",
      colors: ['#2ECE5B'],
      width: 1,
      dashArray: 0,
    },
    dataLabels: {
      enabled: false,
    },
    // theme: {
    //   mode: "#00223C",
    //   palette: "palette2",
    // },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };
  const series = [
    {
      name: "Relatif Getiri",
      data: data,
    },
    { name: "Kümülatif Getiri", data: data2 },
  ];
  const handleToggleSeries = (seriesName) => {
    if (chartRef.current) {
      chartRef.current.chart.toggleSeries(seriesName);
      setActiveSeries(prevState => ({
        ...prevState,
        [seriesName]: !prevState[seriesName]
      }));
    }
  };
  //return <Chart options={options} series={series} type="area" />;
  return (
    <div>
      <Chart ref={chartRef} options={options} series={series} type="area" />
      <div className="nextprev returns">
      <button
  className={`getiri ${activeSeries["Relatif Getiri"] ? 'active' : ''}`}
  onClick={() => handleToggleSeries("Relatif Getiri")}
>
  {activeSeries["Relatif Getiri"] ? "✓ Relatif Getiri" : "Relatif Getiri"}
</button>

<button
  className={`getiri ${activeSeries["Kümülatif Getiri"] ? 'active' : ''}`}
  onClick={() => handleToggleSeries("Kümülatif Getiri")}
>
  {activeSeries["Kümülatif Getiri"] ? "✓ Kümülatif Getiri" : "Kümülatif Getiri"}
</button>
      </div>
    </div>
  );
};

export default ApexChart;
